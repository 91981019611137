import { h } from "preact";
import { Route, Router } from "preact-router";

import Home from "@src/routes/home";
import Title from "@src/components/title";
import Favicon from "@src/components/favicon";

const Error = () => {
  return <div>This page doesn't exist!</div>;
};

const App = () => (
  <div id="app">
    <Title title="Outing - AI Travel Assistant" />
    <Favicon />
    <Router>
      <Route path="/" component={Home} />
      <Route default component={Error} />
    </Router>
  </div>
);

export default App;
