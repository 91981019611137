import { h } from "preact";
import { useEffect } from "preact/hooks";

const Title = (props: Props) => {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);

  return null;
};

interface Props {
  title: string;
}

export default Title;
